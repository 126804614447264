<template>
  <div>
    <el-container id="marketing-container">
      <el-aside
          width="50px"
          style="background-color: black;"
          class="jobmenu"
      >
        <navigation/>
      </el-aside>

      <el-container>
        <el-main>
      <el-button size="small" @click="simple_job_view()">Simple Job View</el-button>    
      <JobProgress :active_tasks="active_tasks"/>

          <StopWatch/>

          <el-tabs type="card">
            <el-tab-pane label="Full Overview">
              <JobOverview
                  :proposal="proposal"
                  :holdpoint_tasks="holdpoint_tasks"
              />
            </el-tab-pane>
            <el-tab-pane
                v-if="show_tasks_editor"
                label="Tasks Editor"
                :lazy="true"
            >
              <ProjectPlan
                  :proposal="proposal"
                  :editor_only="true"
              />
            </el-tab-pane>
            <el-tab-pane
                v-if="show_project_plan"
                label="Project Plan"
                :lazy="true"
                :show_all="true"
            >
              <ProjectPlan
                  :proposal="proposal"
                  :editor_only="false"
              />
            </el-tab-pane>
            <el-tab-pane label="Job Details">
              <JobDetails :proposal="proposal"/>
            </el-tab-pane>

            <el-tab-pane
                label="Hold Points"
                :lazy="true"
            >
              <JobHoldPoints :holdpoint_tasks="holdpoint_tasks"/>
            </el-tab-pane>
            <el-tab-pane
                label="Stock Items"
                :lazy="true"
            >
              <JobStockList/>
            </el-tab-pane>

            <el-tab-pane label="List & Tasks">
              <TaskList/>
            </el-tab-pane>

            <!-- Details section end -->
            <!-- STOCK ITEMS -->

            <el-tab-pane
                class="job-tabs"
                label="Communication"
                :lazy="true"
            >
              <div id="2">
                <JobCommunication
                    ref="2"
                    :proposal="proposal"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane
                class="job-tabs"
                label="Locations"
                :lazy="true"
            >
              LOCATIONS
              <JobLocations :proposal="proposal"/>
            </el-tab-pane>

            <el-tab-pane
                class="job-tabs"
                label="Non Conformance Reports"
                :lazy="true"
            >
              <NonConformance :proposal="proposal"/>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Navigation from '../job_views/Navigation.vue';
import JobDetails from '../job_views/sections/JobDetails.vue';
import JobCommunication from '../job_views/sections/JobCommunication.vue';
import TaskList from '../job_views/sections/TaskList.vue';
import JobStockList from '../job_views/sections/JobStockList.vue';
import StopWatch from '../job_views/sections/StopWatch.vue';
import NonConformance from '../job_views/sections/NonConformance.vue';

import JobOverview from '../job_views/sections/JobOverview.vue';
import ProjectPlan from '../job_views/project_plan/ProjectPlan.vue';

import Details from './../job_views/Details';
import JobLocations from '../job_views/sections/JobLocations.vue';
import JobProgress from '../job_views/sections/JobProgress.vue';
import JobHoldPoints from '../job_views/sections/JobHoldPoints.vue';
import StockListPreviewUseOnly from '../job_views/sections/StockListPreviewUseOnly.vue';

import {mapGetters, mapActions, mapMutations} from 'vuex';
import {editor_store} from 'store/modules/editor';
import {ProposalMixin} from 'mixins/ProposalMixin.js';

import {loadIsHours} from '../../api/organization'
export default {
  name: 'JobView',
  components: {
    NonConformance,
    navigation: Navigation,
    Details,
    JobDetails,
    JobCommunication,
    JobStockList,
    TaskList,
    StopWatch,
    JobOverview,
    JobLocations,
    JobHoldPoints,
    JobProgress,
    StockListPreviewUseOnly,
    ProjectPlan,
  },
  mixins: [ProposalMixin],
  store: editor_store,
  data() {
    return {
      visible: false,
      show_project_plan: false,
      show_tasks_editor: false,
      activeName: 'first',
      requestedDeadline: true,
      requestedDate: true,
      dated: true,
      buttons: [
        'fullsize',
        'source',
        '|',
        'table',
        'image',
        'link',
        '|',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        '|',
        'paragraph',
        'font',
        'fontsize',
        '|',
        'ul',
        'ol',
        '|',
        'undo',
        'redo',
        '|',
        'selectall',
        'cut',
        'copy',
        'paste',
        'copyformat',
        '|',
        'align',
        'outdent',
        'indent',
        '|',
        'hr',
        'brush',
        'eraser',
        'symbol',
        '|',
        'superscript',
        'subscript',
        '|',
        'print',
      ],
      defaultActionOnPaste: 'insert_clear_html',
    };
  },
  created() {
    loadIsHours(this).then(({data}) => {
      this.set_is_hours(data.is_hours);
    });
  },
  mounted() {
    let arr = window.location.search.substring(1).split('=');
    if (arr.length < 1) {
      return;
    }
    this.set_proposal_id(arr[1]);
    this.load_proposal_details();
    this.load_notes();
    this.load_user_info();
  },
  computed: {
    ...mapGetters(['proposal',
      'proposal_id',
      'job_proposals_list',
      'current_user_info'
    ]),
    active_tasks() {
      return this.job_proposals_list.filter(
          (c) =>
              c.is_active == 1 && c.completed == false && c.mark_as_deleted == 0
      );
    },
    holdpoint_tasks() {
      return this.job_proposals_list.filter(
          (c) => c.is_on_hold == 1 && c.completed == false
      );
    },
  },
  methods: {
    ...mapActions([
      'set_proposal',
      'set_proposal_id',
      'set_current_user_info',
      'set_notes_list',
      'load_charge_pos_list',
      'set_is_hours'
    ]),
    simple_job_view(){
      window.location = `/simple_job_view/${this.proposal_id}`;
    },
    load_user_info() {
      this.$http.get('/get_current_user').then(response => {
        this.set_current_user_info(response.data);
      });
    },
    update_project_plan_view() {
      let params = {category_task_id: this.proposal.category_task_id};
      this.$http.post('/is_project_planner', params).then((response) => {
        if (response.data == true) {
          this.show_project_plan = true;
          return;
        } else {
          this.$http.post('/is_tasks_editor').then((response) => {
            if (response.data == true) this.show_tasks_editor = true;
          });
        }
      });
    },
    load_proposal_details() {
      let _this = this;
      this.get_proposal_details(this.proposal_id).then(
          (response) => {
            _this.set_proposal(response);
            _this.update_project_plan_view();
            _this.load_charge_pos_list();
          },
          (reason) => {
          }
      );
    },
    load_notes() {
      let _this = this;
      this.get_notes_list(this.proposal_id).then(
          (response) => {
            _this.set_notes_list(response);
          },
          (reason) => {
          }
      );
    },
    index() {
      return new Promise((resolve, reject) => {
        let params = {
          account_id: this.account_id,
          proposal_id: this.proposal_id,
        };
        this.$http.post('/job_view_data', params).then(
            (response) => {
              resolve((this.proposal = response.data.proposal));
            },
            function (response) {
              this.$message({
                message: response.data.errors,
                type: 'error',
              });
              reject(response);
            }
        );
      });
    },
  },
};
</script>

<style>
.el-tabs__content {
  padding: 20px;
}

h3 {
  margin: 0.4rem 0 0.4rem 0;
}

.icon-btton {
  padding: 0px 10px 0px 0px !important;
  border: 0px !important;
}

.mynotes {
  background: #ffff99;
  padding: 5px;
  color: #999;
  display: none;
  font-size: 10px;
}

span.sales-badge {
  padding: 10px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1px;
  float: right;
}

.el-form-item {
  margin-bottom: 0px !important;
}

/* .el-form-item__label {
  line-height: 20px !important;
  padding: 0 0px 0 0 !important;
  text-align: left !important;
} */

.el-icon {
  font-size: 20px;
  /* border-right: 1px solid #333 !important; */
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background: #000 !important;
  color: #fff !important;
}

.lines {
  background: #f7f7f7;
  padding: 3px 3px;
  margin-bottom: 2px;
  float: left;
  width: 100%;
  font-size: 14px;
}

.disabledlisting {
  text-decoration: line-through;
  background: rgb(196, 196, 196);
}

.activelisting {
  background: rgb(118, 196, 0) !important;
  padding: 10px;
  color: #fff !important;
  float: left;
}

.lines2 {
  background: #999;
  color: #fff;
  padding: 3px 3px;
  margin-bottom: 2px;
  float: left;
  width: 100%;
  font-size: 14px;
}

.status-lines {
  float: left;
  width: 100%;
  background: rgb(165, 165, 165);
  height: 24px;
  border-bottom: 3px solid #fff;
  font-family: Verdana, Geneva, sans-serif;
}

.spanlabel {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.el-form-item__label {
  font-weight: bold;
  font-size: 12px !important;
  text-transform: uppercase;
}

.job-icon-btton {
  background: none !important;
  padding: 0px !important;
  border: 0px !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  background: #f3f3f3;
  border-bottom: 3px solid #999;
  text-transform: uppercase;
  margin-left: 5px;
}

.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background: #999 !important;
  border-bottom-color: #999 !important;
}

.el-tabs--card > .el-tabs__header .el-tabs__item:hover {
  background: #999 !important;
  border-bottom-color: #999 !important;
  color: #fff;
}

.el-tabs__nav-scroll {
  border-bottom: 3px solid #999;
}

.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0px solid #e4e7ed !important;
}

.notification_bkground {
  background: #000 !important;
}

.inputdiv ::-webkit-input-placeholder {
  /* Chrome and Safari */
  color: #999 !important;
  text-transform: uppercase !important;
}

.inputdiv :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999 !important;
  text-transform: uppercase !important;
  opacity: 1;
}

.inputdiv ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999 !important;
  text-transform: uppercase !important;
  opacity: 1;
}

.inputdiv :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999 !important;
  text-transform: uppercase !important;
}

.inputdiv ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999 !important;
  text-transform: uppercase !important;
}

@media only screen and (max-width: 480px) {
  .jobmenu {
    display: none;
  }

  .el-tabs__content {
    padding: 10px;
  }
}
</style>
